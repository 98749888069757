import React, { Component } from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing";
import ProjectListing from "../components/ProjectListing";
import SEO from "../components/SEO";
import config from "../../data/SiteConfig";
import projects from "../../data/projects";
import alex from "../../content/images/alex.jpeg";

export default class Index extends Component {
  render() {
    const { data } = this.props;

    const latestPostEdges = data.latest.edges;

    return (
      <Layout>
        <Helmet title={`${config.siteTitle}`} />
        <SEO />
        <div className="container">
          <div className="lead">
            <div className="avatar-card">
              <img src={alex} alt="Alex" />
            </div>

            <div className="elevator">
              <h1>{`Hi, Alex here 👋`} </h1>
              <p>
                I&apos;m a cryptographic engineer, aspired to empower the
                general public when &quot;
                <a
                  href="https://www.schneier.com/blog/archives/2016/03/cryptography_is.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  math has no agency
                </a>
                &quot;.
              </p>
            </div>
          </div>
        </div>

        <div className="container front-page">
          <section className="section">
            <h2>
              Latest Articles
              <Link to="/blog" className="view-all float-button">
                View all
              </Link>
            </h2>
            <PostListing simple postEdges={latestPostEdges} />
          </section>

          {/* <section className="section"> */}
          {/*   <h2> */}
          {/*     Most Popular */}
          {/*     <Link to="/categories/popular" className="view-all"> */}
          {/*       View all */}
          {/*     </Link> */}
          {/*   </h2> */}
          {/*   <PostListing simple postEdges={popularPostEdges} /> */}
          {/* </section> */}

          <section className="section">
            <h2>Projects</h2>
            <ProjectListing projects={projects} />
          </section>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 5
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
    popular: allMarkdownRemark(
      limit: 9
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Popular" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
  }
`;
